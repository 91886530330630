<template>
	<div class="not-found-container">
		<div class="illustration-container">
			<div class="error-message">Whoops!</div>
			<div class="error-code">404</div>
			<NotFoundIllustration />
		</div>
		<div
			class="help-text"
		>You Seem to be lost because what you're looking for does not exist or may have been removed</div>
		<v-btn to="/" color="primary">Go Home</v-btn>
	</div>
</template>

<script>
import NotFoundIllustration from "../assets/Illustrations/Not Found Graphic.svg";
export default {
	components: {
		NotFoundIllustration,
	},
	mounted() {
		document.querySelector(
			".not-found-container .illustration-container"
		).style.height = `${
			document.querySelector(".not-found-container .illustration-container svg")
				.clientHeight
		}px`;
	},
};
</script>

<style lang="scss">
.not-found-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	.illustration-container {
		width: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.error-message {
		z-index: 2;
		font-size: xx-large;
	}
	.error-code {
		z-index: 2;
		font-size: 100px;
	}
	.help-text {
		margin-top: 30px;
		font-size: larger;
		// color: darken($color: $text-color, $amount: 20);
		font-weight: 900;
		padding: 0 20px;
	}
	svg {
		width: 100%;
		background-color: transparent;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		max-width: 954px;
		max-height: 674px;
	}
}
</style>